import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Menu = _resolveComponent("Menu")!
  const _component_Layers = _resolveComponent("Layers")!
  const _component_Map = _resolveComponent("Map")!
  const _component_SidePanel = _resolveComponent("SidePanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SidePanel, {
      isOpen: _ctx.menuSidePanel,
      mode: _ctx.MODES.OVERLAY,
      position: _ctx.POSITIONS.LEFT
    }, {
      "panel-content": _withCtx(() => [
        _createVNode(_component_Menu, { onCloseClick: _ctx.onMenuClick }, null, 8, ["onCloseClick"])
      ]),
      content: _withCtx(() => [
        _createVNode(_component_SidePanel, {
          isOpen: _ctx.layersSidePanel,
          mode: _ctx.MODES.PUSH,
          position: _ctx.POSITIONS.BOTTOM
        }, {
          "panel-content": _withCtx(() => [
            _createVNode(_component_Layers, {
              onCloseClick: _ctx.onLayersClick,
              onRedrawSublayers: _ctx.onShowSublayerGroups
            }, null, 8, ["onCloseClick", "onRedrawSublayers"])
          ]),
          content: _withCtx(() => [
            _createVNode(_component_Map, {
              sublayers: _ctx.sublayers,
              onLayersClick: _ctx.onLayersClick,
              onMenuClick: _ctx.onMenuClick
            }, null, 8, ["sublayers", "onLayersClick", "onMenuClick"])
          ]),
          _: 1
        }, 8, ["isOpen", "mode", "position"])
      ]),
      _: 1
    }, 8, ["isOpen", "mode", "position"])
  ]))
}