
import { defineComponent } from "vue";
import type { PropType } from "vue";
import type { GeneticTypeWithReliefShapes, ReliefShape } from "./Layers.vue";
import Dialog from "@/components/dialog";
import LayerOpacitySlider from "@/components/inputs/Slider/LayerOpacitySlider.vue";
import { LAYER_NAMES } from "@/constants";
import legend from "@/legend.json";

export default defineComponent({
  name: "LayersTree",
  components: { Dialog, LayerOpacitySlider },
  props: {
    data: Object as PropType<GeneticTypeWithReliefShapes>,
    iconSize: {
      type: Number,
      default: 24,
    },
    smallIconSize: {
      type: Number,
      default: 18,
    },
  },
  computed: {
    itemsInfoAvailable(): boolean {
      return this.$i18n.locale === "cs";
    },
  },
  data() {
    return {
      LAYER_NAMES,
      maxHeight: "100px",
      showInfoDialog: false,
      infoDialogTitle: "",
      infoDialogContent: "",
    };
  },
  mounted() {
    this.setMaxHeight();
    window.addEventListener("resize", this.setMaxHeight);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.setMaxHeight);
  },
  methods: {
    setMaxHeight() {
      const screenHeight = window.innerHeight;
      const maxPerc = 50;
      const maxHeight = Math.floor((screenHeight * maxPerc) / 100);
      this.maxHeight = `${maxHeight}px`;
    },
    onLegendGroupClick(key: string | number) {
      this.$emit("toggleSublayerGroup", key);
    },
    onLegendItemClick(groupKey: string | number, reliefShapeIndex: number) {
      this.$emit("toggleSublayer", groupKey, reliefShapeIndex);
    },
    getLegendImgUrl(img: string): string {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      return require(`@/assets/legendImages/legend-img-${img}.png`);
    },
    sortReliefShapes(reliefShapes: ReliefShape[]): ReliefShape[] {
      return reliefShapes.sort(
        (a, b) => a.reliefShapeIndex - b.reliefShapeIndex
      );
    },
    showGeneticTypeInfo(geneticTypeId: string) {
      const titleTranslation = `legend.geneticTypes.${geneticTypeId}`;
      const contentTranslation = `geneticTypesDescription.${geneticTypeId}`;
      this.infoDialogTitle = this.$t(titleTranslation);
      this.infoDialogContent = this.$t(contentTranslation);

      this.showInfoDialog = true;
    },
    showReliefShapeInfo(reliefShapeIndex: string) {
      const reliefShapeId = this.getReliefShapeIdByIndex(reliefShapeIndex);

      const titleTranslation = `legend.reliefShapes.${reliefShapeId}`;
      const contentTranslation = `reliefShapesDescription.${reliefShapeId}`;
      this.infoDialogTitle = this.$t(titleTranslation);
      this.infoDialogContent = this.$t(contentTranslation);

      this.showInfoDialog = true;
    },
    getReliefShapeIdByIndex(index: string): string | undefined {
      return Object.keys(legend).find(
        (id) => legend[id as keyof typeof legend].index === parseInt(index)
      );
    },
  },
});
