import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import mdiVue from "mdi-vue/v3";
import * as mdijs from "@mdi/js";
import App from "./App.vue";
import store from "./store";
import messages from "./locales";

const i18n = createI18n({
  locale: "cs", // default locale
  messages,
});

createApp(App)
  .use(i18n)
  .use(store)
  .use(mdiVue, {
    icons: mdijs,
  })
  .mount("#app");
