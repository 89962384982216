
import { defineComponent } from "vue";

export default defineComponent({
  name: "MapButtons",
  props: {
    iconSize: {
      type: Number,
      default: 32,
    },
  },
  methods: {
    onMenuClick() {
      this.$emit("menuClick");
    },
    onLayersClick() {
      this.$emit("layersClick");
    },
  },
});
