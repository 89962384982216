import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-24c7edf7"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "popup",
  ref: "popup"
}
const _hoisted_2 = { class: "popup-header flex" }
const _hoisted_3 = { class: "popup-title" }
const _hoisted_4 = {
  key: 0,
  class: "popup-content"
}
const _hoisted_5 = { class: "popup-crd flex" }
const _hoisted_6 = { class: "flex" }
const _hoisted_7 = { class: "info-data" }
const _hoisted_8 = {
  key: 0,
  class: "genetic-type-group"
}
const _hoisted_9 = { class: "genetic-type-title" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "features" }
const _hoisted_12 = { class: "feature-data" }
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "index-number" }
const _hoisted_15 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("info.title")), 1),
      _createElementVNode("div", {
        class: "popup-close-btn",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
      }, [
        _createVNode(_component_mdicon, {
          name: "close",
          size: 20
        })
      ])
    ]),
    (_ctx.showPopup)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", null, _toDisplayString(_ctx.lonLatStr), 1),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", {
                class: "popup-btn",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.switchCrdFormat && _ctx.switchCrdFormat(...args)))
              }, [
                _createVNode(_component_mdicon, {
                  name: "menu-swap",
                  size: 28
                })
              ]),
              _createElementVNode("div", {
                class: "popup-btn",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.crdToClipboard(_ctx.lonLatStr)))
              }, [
                _createVNode(_component_mdicon, {
                  name: "content-copy",
                  size: 20
                })
              ])
            ])
          ]),
          _createElementVNode("ul", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.createInfoData(_ctx.features), (geneticType, key) => {
              return (_openBlock(), _createElementBlock("li", { key: key }, [
                (geneticType.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("span", null, _toDisplayString(key), 1),
                        (_ctx.itemsInfoAvailable)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                              _createVNode(_component_mdicon, {
                                name: "information-outline",
                                class: "info-icon",
                                size: "24",
                                onClick: ($event: any) => (_ctx.showGeneticTypeInfo(geneticType[0].geneticTypeId))
                              }, null, 8, ["onClick"])
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("ul", _hoisted_11, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortFeatures(geneticType), (featureData, key) => {
                          return (_openBlock(), _createElementBlock("li", {
                            key: `feature-${key}`
                          }, [
                            _createElementVNode("span", _hoisted_12, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(featureData.images, (img, key) => {
                                return (_openBlock(), _createElementBlock("span", {
                                  key: `image-${key}`
                                }, [
                                  _createElementVNode("img", {
                                    src: _ctx.getImageSrc(img),
                                    alt: "feature image"
                                  }, null, 8, _hoisted_13)
                                ]))
                              }), 128)),
                              _createElementVNode("span", _hoisted_14, _toDisplayString(featureData.index), 1),
                              _createElementVNode("span", null, _toDisplayString(featureData.reliefShape), 1),
                              (_ctx.itemsInfoAvailable)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_15, [
                                    _createVNode(_component_mdicon, {
                                      name: "information-outline",
                                      class: "info-icon",
                                      size: "18",
                                      onClick: ($event: any) => (_ctx.showReliefShapeInfo(featureData.unit))
                                    }, null, 8, ["onClick"])
                                  ]))
                                : _createCommentVNode("", true)
                            ])
                          ]))
                        }), 128))
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ], 512)), [
    [_vShow, _ctx.showPopup]
  ])
}