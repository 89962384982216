import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2660ae47"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "map-button",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onMenuClick && _ctx.onMenuClick(...args)))
    }, [
      _createVNode(_component_mdicon, {
        name: "menu",
        size: _ctx.iconSize
      }, null, 8, ["size"])
    ]),
    _createElementVNode("div", {
      class: "map-button",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onLayersClick && _ctx.onLayersClick(...args)))
    }, [
      _createVNode(_component_mdicon, {
        name: "layers",
        size: _ctx.iconSize
      }, null, 8, ["size"])
    ])
  ]))
}