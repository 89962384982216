import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-129de410"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['side-panel', _ctx.modeCssClass, _ctx.layoutCssClass, _ctx.positionCssClass])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([
        'panel-content',
        _ctx.isOpen ? 'open' : 'close',
        _ctx.useTransition ? 'transition' : '',
      ])
    }, [
      _renderSlot(_ctx.$slots, "panel-content", {}, undefined, true)
    ], 2),
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "content", {}, undefined, true)
    ])
  ], 2))
}