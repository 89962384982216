import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Select = _resolveComponent("Select")!

  return (_openBlock(), _createBlock(_component_Select, {
    options: _ctx.options,
    value: _ctx.defaultValue,
    onChange: _ctx.switchLanguage
  }, null, 8, ["options", "value", "onChange"]))
}