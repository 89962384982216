import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3aa18da5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "slider-container" }
const _hoisted_2 = ["min", "max", "step", "value"]
const _hoisted_3 = { class: "value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      class: "slider",
      type: "range",
      min: _ctx.min,
      max: _ctx.max,
      step: _ctx.step,
      value: _ctx.value,
      style: _normalizeStyle({ width: _ctx.width }),
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateValue && _ctx.updateValue(...args)))
    }, null, 44, _hoisted_2),
    _createElementVNode("span", _hoisted_3, _toDisplayString(`${_ctx.value}${_ctx.units}`), 1)
  ]))
}