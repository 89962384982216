
import { defineComponent } from "vue";
import Select, { ISelectOption } from "./";

export default defineComponent({
  name: "LocaleSelect",
  components: { Select },
  data() {
    return {
      defaultValue: "cs",
      options: [
        { value: "cs", label: "cs" },
        { value: "de", label: "de" },
        { value: "en", label: "en" },
      ] as ISelectOption[],
    };
  },
  methods: {
    switchLanguage(event: Event) {
      const locale = (event.target as HTMLSelectElement).value;
      this.$i18n.locale = locale;
    },
  },
});
