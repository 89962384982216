
import Layer from "ol/layer/Layer";
import LayerRenderer from "ol/renderer/Layer";
import Source from "ol/source/Source";
import { defineComponent, PropType } from "vue";
import { mapGetters } from "vuex";
import Slider from "./";

export default defineComponent({
  name: "LayerOpacitySlider",
  components: { Slider },
  props: {
    layerNames: {
      type: Array as PropType<string[]>,
      required: true,
    },
    width: {
      type: String,
      default: "200px",
    },
  },
  computed: {
    ...mapGetters(["map"]),
  },
  data() {
    return {
      value: 100,
      mapExists: false,
    };
  },
  watch: {
    map(mapInstance) {
      this.mapExists = !!mapInstance;
    },
    mapExists(exists) {
      if (exists) {
        const layer = this.getLayer(this.layerNames[0]);
        const opacity = layer?.getOpacity() || 0;
        this.value = opacity * 100;
      }
    },
  },
  methods: {
    setLayerOpacity(value: number) {
      this.value = value;
      const opacity = value / 100;

      this.layerNames.forEach((layerName) => {
        const layer = this.getLayer(layerName);
        layer?.setOpacity(opacity);
      });
    },
    getLayer(layerName: string): Layer<Source, LayerRenderer<any>> | undefined {
      const layers = this.map?.getAllLayers();
      return layers?.find((layer: any) => layer.get("name") == layerName);
    },
  },
});
