import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Slider = _resolveComponent("Slider")!

  return (_ctx.mapExists)
    ? (_openBlock(), _createBlock(_component_Slider, {
        key: 0,
        min: 0,
        max: 100,
        step: 1,
        value: _ctx.value,
        units: "%",
        width: _ctx.width,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.setLayerOpacity($event)))
      }, null, 8, ["value", "width"]))
    : _createCommentVNode("", true)
}