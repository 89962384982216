import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f83a001"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "error"
}
const _hoisted_2 = {
  key: 1,
  class: "error"
}
const _hoisted_3 = {
  key: 2,
  class: "suggestions"
}
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Search, {
      onInput: _ctx.onInput,
      onSearch: _ctx.onSearch
    }, null, 8, ["onInput", "onSearch"]),
    (_ctx.searchError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t("errors.searchLocation.searchFailed")), 1))
      : (_ctx.notFound)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t("errors.searchLocation.notFound")), 1))
        : (_ctx.suggestions.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.suggestions, (suggestion, i) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: i,
                  class: "suggestion",
                  onClick: ($event: any) => (_ctx.apply(suggestion.lon, suggestion.lat))
                }, _toDisplayString(suggestion.display_name), 9, _hoisted_4))
              }), 128))
            ]))
          : _createCommentVNode("", true)
  ]))
}