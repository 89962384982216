
import { defineComponent } from "vue";

export default defineComponent({
  name: "Slider",
  props: {
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    step: {
      type: Number,
      default: 1,
    },
    value: {
      type: Number,
      default: 0,
    },
    units: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "100%",
    }
  },
  methods: {
    updateValue(event: Event) {
      const target = event.target as HTMLInputElement;
      const value = parseFloat(target.value);
      this.$emit("update:value", value);
    },
  },
});
