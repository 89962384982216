import ImageLayer from "ol/layer/Image";
import TileLayer from "ol/layer/Tile";
import TileJSON from "ol/source/TileJSON";
import ImageWMS from "ol/source/ImageWMS";
import { URLS, LAYER_NAMES, MAPTILER_API_KEY } from "./constants";

export default class Layers {
  static hillshade(): ImageLayer<ImageWMS> {
    const params = {
      layers: "dmr5g:GrayscaleHillshade",
    };

    const attributions =
      "© <a href='https://www.cuzk.cz/' target='_blank' rel='noopener noreferrer'>ČÚZK</a>";

    const source = new ImageWMS({
      url: "https://ags.cuzk.cz/arcgis2/services/dmr5g/ImageServer/WMSServer/",
      params,
      attributions,
    });

    const properties = { name: LAYER_NAMES.HILLSHADE };

    return new ImageLayer({
      source,
      properties,
    });
  }

  static topo(): TileLayer<TileJSON> {
    const source = new TileJSON({
      url: `https://api.maptiler.com/maps/basic-v2-light/tiles.json?key=${MAPTILER_API_KEY}`,
      tileSize: 512,
      crossOrigin: "anonymous",
    });

    const properties = { name: LAYER_NAMES.TOPO };

    return new TileLayer({
      source,
      opacity: 0.4,
      properties,
    });
  }

  static geomorphologyPolygons(): ImageLayer<ImageWMS> {
    const url = URLS.GEOMORPHOLOGY;
    const LAYERS = "geopark_polygons";
    // const CQL_FILTER = "INDEX=11"
    const attributions =
      "© <a href='http://www.georespect.cz/' target='_blank' rel='noopener noreferrer'>GEORESPECT Ltd.</a>";
    const params = { LAYERS };
    // const params = { LAYERS, CQL_FILTER };
    const serverType = "geoserver";
    const opacity = 1;
    const properties = { name: LAYER_NAMES.GEOMORPHOLOGY_POLYGONS };

    const source = new ImageWMS({
      url,
      params,
      serverType,
      attributions,
    });

    return new ImageLayer({
      source,
      opacity,
      properties,
    });
  }

  static geomorphology(): ImageLayer<ImageWMS> {
    const url = URLS.GEOMORPHOLOGY;
    const LAYERS = "geopark_polygons_hatch,geopark_morpholineaments,geopark_lines,geopark_drainage,geopark_cannal,geopark_points";
    const attributions =
      "© <a href='http://www.georespect.cz/' target='_blank' rel='noopener noreferrer'>GEORESPECT Ltd.</a>";
    const params = { LAYERS };
    const serverType = "geoserver";
    const opacity = 1;
    const properties = { name: LAYER_NAMES.GEOMORPHOLOGY };

    const source = new ImageWMS({
      url,
      params,
      serverType,
      attributions,
    });

    return new ImageLayer({
      source,
      opacity,
      properties,
    });
  }

  static geomorphologyInfo(): ImageLayer<ImageWMS> {
    const url = URLS.GEOMORPHOLOGY;
    const LAYERS = "geopark-sumava:geopark-sumava";
    const attributions =
      "© <a href='http://www.georespect.cz/' target='_blank' rel='noopener noreferrer'>GEORESPECT Ltd.</a>";
    const params = { LAYERS };
    const serverType = "geoserver";
    const opacity = 0;
    const properties = { name: LAYER_NAMES.GEOMORPHOLOGY_INFO };

    const source = new ImageWMS({
      url,
      params,
      serverType,
      attributions,
    });

    return new ImageLayer({
      source,
      opacity,
      properties,
    });
  }
}
