
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import SidePanelTitle from "./SidePanel/SidePanelTitle.vue";
import LocaleSelect from "@/components/inputs/Select/LocaleSelect.vue";
import SearchLocation from "@/components/inputs/Search/SearchLocation.vue";
import Geolocation from "@/components/Geolocation.vue";

export default defineComponent({
  name: "Menu",
  components: { SidePanelTitle, LocaleSelect, SearchLocation, Geolocation },
  computed: {
    ...mapGetters(["map"]),
  },
  methods: {
    emitClose() {
      this.$emit("closeClick");
    },
  },
});
