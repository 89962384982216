
import { defineComponent } from "vue";

export default defineComponent({
  name: "SidePanelTitle",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  methods: {
    onCloseClick() {
      this.$emit("closeClick");
    },
  },
});
