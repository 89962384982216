
import { defineComponent } from "vue";

export default defineComponent({
  name: "Checkbox",
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
  },
  methods: {
    updateValue(event: Event) {
      const target = event.target as HTMLInputElement;
      const value = target.checked;
      this.$emit("update:value", value);
    },
  },
});
