const GEOSERVER_URL = "https://api.geomapasumavy.cz/geoserver/";

const URLS = {
  GEOSERVER: GEOSERVER_URL,
  GEOMORPHOLOGY: `${GEOSERVER_URL}geopark-sumava/wms`,
};

const LAYER_NAMES = {
  GEOMORPHOLOGY: "geomorphology",
  GEOMORPHOLOGY_POLYGONS: "geomorphologyPolygons",
  GEOMORPHOLOGY_INFO: "geomorphologyInfo",
  TOPO: "topo",
  HILLSHADE: "hillshade",
  MY_LOCATION: "myLocation",
};

const MAPTILER_API_KEY = "VG11MmoPWG6NJRj4lusl";

export { URLS, LAYER_NAMES, MAPTILER_API_KEY };
