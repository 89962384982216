
import { defineComponent, PropType } from "vue";
import Search from "./";
import Map from "ol/Map";
import axios from "axios";
import { fromLonLat } from "ol/proj";

interface IResponseData {
  lon: string;
  lat: string;
  display_name: string;
}

export enum METHODS {
  MOST_RELEVANT = "mostRelevant",
  SUGGESTIONS = "suggestions",
}

export default defineComponent({
  name: "SearchLocation",
  components: { Search },
  props: {
    map: {
      type: Object as PropType<Map>,
      required: true,
    },
    method: {
      type: String as PropType<METHODS>,
      default: METHODS.SUGGESTIONS,
    },
  },
  data() {
    return {
      suggestions: [] as IResponseData[],
      notFound: false,
      searchError: false,
    };
  },
  methods: {
    reset() {
      if (this.suggestions.length) {
        this.suggestions = [];
      }
      if (this.notFound) {
        this.notFound = false;
      }
      if (this.searchError) {
        this.searchError = false;
      }
    },
    onInput() {
      this.reset();
    },
    async onSearch(searchValue: string) {
      this.reset();
      const url = this.createUrl(searchValue);

      try {
        const response = await axios.get(url);
        const { data } = response;

        if (this.method === METHODS.MOST_RELEVANT) {
          this.applyMostRelevant(data);
        } else if (this.method === METHODS.SUGGESTIONS) {
          this.displaySuggestions(data);
        }
      } catch (error) {
        console.log("error: ", error);
        this.searchError = true;
      }
    },
    createUrl(searchValue: string): string {
      const baseUrl = "https://nominatim.openstreetmap.org/search";
      const q = encodeURIComponent(searchValue);
      const countrycodes = "cz";
      const limit = 10;
      const format = "json";

      return `${baseUrl}?countrycodes=${countrycodes}&q=${q}&limit=${limit}&format=${format}`;
    },
    applyMostRelevant(data: IResponseData[]) {
      if (data[0]) {
        const { lon, lat } = data[0];
        this.apply(lon, lat);
      } else {
        this.notFound = true;
      }
    },
    displaySuggestions(data: IResponseData[]) {
      this.suggestions = this.getDistinctData(data);
      if (this.suggestions.length === 0) {
        this.notFound = true;
      }
    },
    apply(lon: string, lat: string) {
      this.zoomToResult(lon, lat);
      this.$emit("searchSuccess");
    },
    zoomToResult(lon: string, lat: string) {
      const centerLonLat = [parseFloat(lon), parseFloat(lat)];
      const center = fromLonLat(centerLonLat);
      const view = this.map.getView();
      const zoom = 15;

      view.setCenter(center);
      view.setZoom(zoom);
    },
    getDistinctData(data: IResponseData[]): IResponseData[] {
      const distinct: IResponseData[] = [];

      data.forEach((item) => {
        const { display_name } = item;
        const displayNames = distinct.map((item) => item.display_name);

        if (!displayNames.includes(display_name)) {
          distinct.push(item);
        }
      });

      return distinct;
    },
  },
});
