
import { defineComponent } from "vue";
import SidePanelTitle from "./SidePanel/SidePanelTitle.vue";
import LayersTree from "./LayersTree.vue";
import messages from "../locales";
import legend from "@/legend.json";

interface LayersData {
  geneticTypesWithReliefShapes: GeneticTypeWithReliefShapes;
}

interface KeyValueObject {
  [key: string]: string;
}

interface LegendObject {
  [key: string]: {
    index: number;
    geneticType: number;
    img: string[];
  };
}

export interface ReliefShape {
  reliefShapeLabel: string;
  reliefShapeIndex: number;
  geneticTypeId: number;
  img: string[];
}

interface MergedObject {
  [key: string]: ReliefShape;
}

export interface GeneticTypeWithReliefShapes {
  [key: string]: {
    geneticTypeLabel: string;
    visible: boolean;
    reliefShapes: {
      reliefShapeLabel: string;
      reliefShapeIndex: number;
      visible: boolean;
    }[];
  };
}

export default defineComponent({
  name: "Layers",
  components: { SidePanelTitle, LayersTree },
  data(): LayersData {
    return {
      geneticTypesWithReliefShapes: {},
    };
  },
  mounted() {
    const locale = this.$i18n.locale;
    this.refreshData(locale);
  },
  watch: {
    "$i18n.locale"(locale) {
      this.refreshData(locale);
    },
  },
  methods: {
    refreshData(locale: string) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { reliefShapes, geneticTypes } = messages[locale].legend;

      const mergedObject = this.mergeObjects(reliefShapes, legend);

      this.geneticTypesWithReliefShapes = this.assignToGeneticTypes(
        geneticTypes,
        mergedObject
      );
    },
    onCloseClick() {
      this.$emit("closeClick");
    },
    onToggleSublayerGroup(key: number | string) {
      this.toggleGeneticTypes(key);
      const legendItemsIndicesString = this.getIndices();

      this.$emit("redrawSublayers", legendItemsIndicesString);
    },
    onToggleSublayer(groupKey: string | number, reliefShapeIndex: number) {
      this.toggleReliefShape(groupKey, reliefShapeIndex);
      const legendItemsIndicesString = this.getIndices();

      this.$emit("redrawSublayers", legendItemsIndicesString);
    },
    mergeObjects(obj1: KeyValueObject, obj2: LegendObject): MergedObject {
      const merged: MergedObject = {};

      for (let key in obj1) {
        if (Object.prototype.hasOwnProperty.call(obj2, key)) {
          merged[key] = {
            reliefShapeLabel: obj1[key],
            reliefShapeIndex: obj2[key].index,
            geneticTypeId: obj2[key].geneticType,
            img: obj2[key].img,
          };
        }
      }

      return merged;
    },
    assignToGeneticTypes(
      geneticTypes: KeyValueObject,
      mergedObject: MergedObject
    ): GeneticTypeWithReliefShapes {
      const resultObject: GeneticTypeWithReliefShapes = {};

      for (const typeId in geneticTypes) {
        const geneticTypeLabel = geneticTypes[typeId];
        const reliefShapes = Object.values(mergedObject).filter(
          (item) => item.geneticTypeId === parseInt(typeId)
        );

        const prevGeneticType = this.geneticTypesWithReliefShapes[typeId];

        resultObject[typeId] = {
          geneticTypeLabel,
          visible:
            prevGeneticType?.visible !== undefined
              ? prevGeneticType?.visible
              : true,
          reliefShapes: reliefShapes.map((shape, i) => ({
            reliefShapeLabel: shape.reliefShapeLabel,
            reliefShapeIndex: shape.reliefShapeIndex,
            img: shape.img,
            visible:
              prevGeneticType?.reliefShapes[i]?.visible !== undefined
                ? prevGeneticType?.reliefShapes[i]?.visible
                : true,
          })),
        };
      }

      return resultObject;
    },
    toggleGeneticTypes(key: string | number): void {
      this.geneticTypesWithReliefShapes[key].visible =
        !this.geneticTypesWithReliefShapes[key].visible;
    },
    toggleReliefShape(gKey: string | number, reliefShapeIndex: number): void {
      const rsIndex = this.geneticTypesWithReliefShapes[
        gKey
      ].reliefShapes.findIndex(
        (item) => item.reliefShapeIndex === reliefShapeIndex
      );

      this.geneticTypesWithReliefShapes[gKey].reliefShapes[rsIndex].visible =
        !this.geneticTypesWithReliefShapes[gKey].reliefShapes[rsIndex].visible;
    },
    getIndices(): string {
      const arr: number[] = [];
      Object.values(this.geneticTypesWithReliefShapes).forEach((gType) => {
        if (gType.visible) {
          Object.values(gType.reliefShapes).forEach((rShape) => {
            if (rShape.visible) {
              arr.push(rShape.reliefShapeIndex);
            }
          });
        }
      });

      return arr.toString();
    },
  },
});
