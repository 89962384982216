
import { defineComponent } from "vue";

export default defineComponent({
  name: "Search",
  data() {
    return {
      value: "",
    };
  },
  methods: {
    updateValue(event: InputEvent) {
      const { value } = event.target as HTMLInputElement;
      this.value = value;
    },
    search() {
      if (this.value.trim() === "") {
        return;
      }
      this.$emit("search", this.value);
    },
  },
});
