
import { defineComponent } from "vue";

export default defineComponent({
  name: "Dialog",
  props: {
    title: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "90%",
    },
    maxWidth: {
      type: String,
      default: "90%",
    },
    contentHeight: {
      type: String,
      default: "300px",
    },
  },
  methods: {
    onCloseClick() {
      this.$emit("closeClick");
    },
  },
});
