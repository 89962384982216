
import { defineComponent, PropType } from "vue";
import {
  MODES,
  POSITIONS,
  MODE_CSS_CLASSES,
  POSITION_CSS_CLASSES,
  POSITIONS_BY_LAYOUT,
  LAYOUTS,
  LAYOUT_CSS_CLASSES,
} from "./constants";

export default defineComponent({
  name: "SidePanel",
  props: {
    mode: {
      type: String as PropType<MODES>,
      default: MODES.PUSH,
    },
    position: {
      type: String as PropType<POSITIONS>,
      default: POSITIONS.BOTTOM,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    useTransition: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isHorizontalLayout(): boolean {
      const { HORIZONTAL } = POSITIONS_BY_LAYOUT;
      return HORIZONTAL.includes(this.position);
    },
    modeCssClass(): string {
      return MODE_CSS_CLASSES[this.mode];
    },
    layoutCssClass(): string {
      const { HORIZONTAL, VERTICAL } = LAYOUTS;
      const layout = this.isHorizontalLayout ? HORIZONTAL : VERTICAL;

      return LAYOUT_CSS_CLASSES[layout];
    },
    positionCssClass(): string {
      return POSITION_CSS_CLASSES[this.position];
    },
  },
});
