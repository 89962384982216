import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Checkbox, {
      checked: _ctx.showMyLocation,
      label: _ctx.$t('menu.showMyLocation'),
      "onUpdate:value": _ctx.onShowMyLocation
    }, null, 8, ["checked", "label", "onUpdate:value"]),
    _createVNode(_component_Checkbox, {
      checked: _ctx.trackMyLocation,
      label: _ctx.$t('menu.trackMyLocation'),
      "onUpdate:value": _ctx.onTrackMyLocation
    }, null, 8, ["checked", "label", "onUpdate:value"])
  ]))
}