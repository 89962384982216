
import { defineComponent } from "vue";
import { ISelectOption } from "./";

export default defineComponent({
  name: "Select",
  props: {
    options: {
      type: Array as () => ISelectOption[],
      required: true,
    },
    value: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      selectedValue: this.value,
    };
  },
});
