import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d171fbc8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dialog-background" }
const _hoisted_2 = { class: "dialog-header" }
const _hoisted_3 = { class: "dialog-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "dialog",
      style: _normalizeStyle(`width: ${_ctx.width}; max-width: ${_ctx.maxWidth}`)
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1),
        _createElementVNode("span", {
          class: "close-btn",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onCloseClick && _ctx.onCloseClick(...args)))
        }, [
          _createVNode(_component_mdicon, {
            name: "close",
            size: 24
          })
        ])
      ]),
      _createElementVNode("div", {
        class: "dialog-content",
        style: _normalizeStyle(`height: ${_ctx.contentHeight}`)
      }, [
        _renderSlot(_ctx.$slots, "content", {}, undefined, true)
      ], 4)
    ], 4)
  ]))
}