import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-62551fee"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["value"]
const _hoisted_2 = { class: "option-content" }
const _hoisted_3 = {
  key: 0,
  class: "option-icon"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "option-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("select", {
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedValue) = $event)),
    class: "select-input"
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
      return (_openBlock(), _createElementBlock("option", {
        key: option.value,
        value: option.value
      }, [
        _createElementVNode("span", _hoisted_2, [
          (option.icon)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                _createElementVNode("img", {
                  src: option.icon,
                  alt: "Icon"
                }, null, 8, _hoisted_4)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("span", _hoisted_5, _toDisplayString(option.label), 1)
        ])
      ], 8, _hoisted_1))
    }), 128))
  ], 512)), [
    [_vModelSelect, _ctx.selectedValue]
  ])
}