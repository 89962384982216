import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e3fc641e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"width":"100%"} }
const _hoisted_2 = { class: "layers-container" }
const _hoisted_3 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SidePanelTitle = _resolveComponent("SidePanelTitle")!
  const _component_LayersTree = _resolveComponent("LayersTree")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SidePanelTitle, {
        title: _ctx.$t('sidePanelTitles.layers'),
        onCloseClick: _ctx.onCloseClick
      }, null, 8, ["title", "onCloseClick"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_LayersTree, {
          data: _ctx.geneticTypesWithReliefShapes,
          onToggleSublayerGroup: _ctx.onToggleSublayerGroup,
          onToggleSublayer: _ctx.onToggleSublayer
        }, null, 8, ["data", "onToggleSublayerGroup", "onToggleSublayer"])
      ])
    ])
  ]))
}