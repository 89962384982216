
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import Geolocation from "ol/Geolocation.js";
import Feature from "ol/Feature.js";
import { Vector as VectorSource } from "ol/source.js";
import { Vector as VectorLayer } from "ol/layer.js";
import Point from "ol/geom/Point.js";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style.js";
import { Geometry, Polygon } from "ol/geom";
import Map from "ol/Map";
import Checkbox from "@/components/inputs/Checkbox";
import { Coordinate } from "ol/coordinate";
import { LAYER_NAMES } from "@/constants";

interface GeolocationData {
  showMyLocation: boolean;
  trackMyLocation: boolean;
  myLocationCoordinates: Coordinate | undefined;
}

export default defineComponent({
  name: "Geolocation",
  components: { Checkbox },
  computed: {
    ...mapGetters(["map"]),
  },
  data(): GeolocationData {
    return {
      showMyLocation: true,
      trackMyLocation: false,
      myLocationCoordinates: undefined,
    };
  },
  watch: {
    map(mapInstance) {
      if (mapInstance) {
        this.init(mapInstance);
      }
    },
    showMyLocation(value) {
      const layer = this.getVectorLayer();
      layer?.setVisible(value);

      if (value && this.myLocationCoordinates) {
        this.map.getView().setCenter(this.myLocationCoordinates);
      }
    },
    trackMyLocation(value) {
      if (value && this.myLocationCoordinates) {
        this.map.getView().setCenter(this.myLocationCoordinates);
      }
    },
  },
  methods: {
    init(map: Map) {
      const geolocation = new Geolocation({
        // enableHighAccuracy must be set to true to have the heading value.
        trackingOptions: {
          enableHighAccuracy: true,
        },
        projection: map.getView().getProjection(),
      });

      // handle geolocation error.
      geolocation.on("error", function (error) {
        console.log("error.message: ", error.message);
      });

      geolocation.setTracking(true);

      const accuracyFeature = this.createAccuracyFeature();
      const positionFeature = this.createPositionFeature();
      const accuracyGeometry = geolocation.getAccuracyGeometry();

      geolocation.on("change:accuracyGeometry", () => {
        accuracyFeature.setGeometry(accuracyGeometry as Polygon);
      });

      geolocation.on("change:position", () => {
        const coordinates = geolocation.getPosition();
        const point = coordinates ? new Point(coordinates) : null;

        positionFeature.setGeometry(point as Geometry);
        this.onPostitionChanged(coordinates);
      });

      const features = [accuracyFeature, positionFeature];
      this.addFeaturesToMap(features, map);
    },
    createAccuracyFeature(): Feature {
      return new Feature();
    },
    createPositionFeature(): Feature {
      const positionFeature = new Feature();
      positionFeature.setStyle(
        new Style({
          image: new CircleStyle({
            radius: 6,
            fill: new Fill({
              color: "#3399CC",
            }),
            stroke: new Stroke({
              color: "#fff",
              width: 2,
            }),
          }),
        })
      );

      return positionFeature;
    },
    addFeaturesToMap(features: Feature<Geometry>[], map: Map) {
      const layer = this.createVectorLayer(features);
      map.addLayer(layer);
    },
    createVectorLayer(features: Feature<Geometry>[]) {
      const properties = { name: LAYER_NAMES.MY_LOCATION };
      const visible = this.showMyLocation;
      const source = new VectorSource({ features });

      return new VectorLayer({
        source,
        properties,
        visible,
      });
    },
    getVectorLayer(): VectorLayer<VectorSource> | undefined {
      const layerName = LAYER_NAMES.MY_LOCATION;
      const layers = this.map?.getAllLayers();
      return layers?.find((layer: any) => layer.get("name") == layerName);
    },
    onShowMyLocation(value: boolean) {
      this.showMyLocation = value;
    },
    onTrackMyLocation(value: boolean) {
      this.trackMyLocation = value;
    },
    onPostitionChanged(coordinates: Coordinate | undefined) {
      this.myLocationCoordinates = coordinates;

      if (this.trackMyLocation && coordinates) {
        this.map.getView().setCenter(coordinates);
      }
    },
  },
});
