enum POSITIONS {
  TOP = "top",
  BOTTOM = "bottom",
  RIGHT = "right",
  LEFT = "left",
}

enum MODES {
  PUSH = "push",
  OVERLAY = "overlay",
}

enum LAYOUTS {
  HORIZONTAL = "horizontal",
  VERTICAL = "vertical",
}

const MODE_CSS_CLASSES = {
  [MODES.PUSH]: "mode-push",
  [MODES.OVERLAY]: "mode-overlay",
};

const POSITION_CSS_CLASSES = {
  [POSITIONS.TOP]: "position-top",
  [POSITIONS.BOTTOM]: "position-bottom",
  [POSITIONS.RIGHT]: "position-right",
  [POSITIONS.LEFT]: "position-left",
};

const POSITIONS_BY_LAYOUT = {
  HORIZONTAL: [POSITIONS.LEFT, POSITIONS.RIGHT],
  VERTICAL: [POSITIONS.TOP, POSITIONS.BOTTOM],
};

const LAYOUT_CSS_CLASSES = {
  [LAYOUTS.HORIZONTAL]: "layout-horizontal",
  [LAYOUTS.VERTICAL]: "layout-vertical",
};

export {
  POSITIONS,
  MODES,
  LAYOUTS,
  MODE_CSS_CLASSES,
  POSITION_CSS_CLASSES,
  POSITIONS_BY_LAYOUT,
  LAYOUT_CSS_CLASSES,
};
