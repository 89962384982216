import { createStore } from "vuex";
import { Map } from "ol";

interface IState {
  map: Map | null;
}

export default createStore({
  state: {
    map: null,
  } as IState,
  mutations: {
    setMap(state: IState, map: Map) {
      state.map = map;
    },
  },
  actions: {
    setMap({ commit }: any, map: Map) {
      commit("setMap", map);
    },
  },
  getters: {
    map(state) {
      return state.map;
    },
  },
  modules: {},
});
