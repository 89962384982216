import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-27b15cf4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "map-container" }
const _hoisted_2 = {
  class: "map",
  ref: "map"
}
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MapButtons = _resolveComponent("MapButtons")!
  const _component_PopupOverlay = _resolveComponent("PopupOverlay")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, null, 512),
    _createVNode(_component_MapButtons, {
      onLayersClick: _ctx.onLayersClick,
      onMenuClick: _ctx.onMenuClick
    }, null, 8, ["onLayersClick", "onMenuClick"]),
    (_ctx.map)
      ? (_openBlock(), _createBlock(_component_PopupOverlay, {
          key: 0,
          features: _ctx.infoFeatures,
          coordinate: _ctx.infoCoordinate,
          onGeneticTypeInfoClik: _ctx.showGeneticTypeInfo,
          onReliefShapeInfoClik: _ctx.showReliefShapeInfo
        }, null, 8, ["features", "coordinate", "onGeneticTypeInfoClik", "onReliefShapeInfoClik"]))
      : _createCommentVNode("", true),
    (_ctx.showInfoDialog)
      ? (_openBlock(), _createBlock(_component_Dialog, {
          key: 1,
          maxWidth: "800px",
          contentHeight: "150px",
          title: _ctx.infoDialogTitle,
          onCloseClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showInfoDialog = false))
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", { innerHTML: _ctx.infoDialogContent }, null, 8, _hoisted_3)
          ]),
          _: 1
        }, 8, ["title"]))
      : _createCommentVNode("", true)
  ]))
}