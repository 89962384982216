
import { defineComponent } from "vue";
import SidePanel, { POSITIONS, MODES } from "./components/SidePanel";
import Map from "./components/Map.vue";
import Menu from "@/components/Menu.vue";
import Layers from "@/components/Layers.vue";

export default defineComponent({
  name: "App",
  components: {
    Map,
    Menu,
    Layers,
    SidePanel,
  },
  data() {
    return {
      POSITIONS,
      MODES,
      menuSidePanel: false,
      layersSidePanel: false,
      sublayers: '',
    };
  },
  methods: {
    onLayersClick() {
      this.layersSidePanel = !this.layersSidePanel;

      if (this.layersSidePanel && this.menuSidePanel) {
        this.menuSidePanel = false;
      }
    },
    onMenuClick() {
      this.menuSidePanel = !this.menuSidePanel;

      if (this.menuSidePanel && this.layersSidePanel) {
        this.layersSidePanel = false;
      }
    },
    onShowSublayerGroups(sublayers: string) {
      this.sublayers = sublayers
    }
  },
});
