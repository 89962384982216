
import { defineComponent, PropType } from "vue";
import { mapGetters } from "vuex";
import Overlay from "ol/Overlay";
import legend from "@/legend.json";
import { Coordinate, toStringHDMS } from "ol/coordinate";
import { toLonLat } from "ol/proj";
import { copyToClipboard } from "@/utils";
import { IInfoFeature } from "./Map.vue";
import messages from "../locales";

enum COORDINATE_FORMAT {
  DEGREES = "deg",
  DECIMALS = "dec",
}

interface PopupOverlayData {
  popupOverlay: Overlay | null;
  coordinateFormat: COORDINATE_FORMAT;
}

interface IFeatureData {
  unit: number;
  index: number;
  geneticType: string;
  geneticTypeId: number;
  reliefShape: string;
  images: string[];
}

interface IInfoData {
  [geneticType: string]: IFeatureData[];
}

export const POPUP_OVERLAY_ID = "popupOverlay";

export default defineComponent({
  name: "PopupOverlay",
  props: {
    features: {
      type: Array as PropType<IInfoFeature[]>,
      required: true,
    },
    coordinate: {
      type: Object as PropType<Coordinate>,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["map"]),
    showPopup(): boolean {
      return this.features && this.features.length > 0;
    },
    lonLat(): Coordinate {
      return toLonLat(this.coordinate);
    },
    lon(): number {
      return this.lonLat[0];
    },
    lat(): number {
      return this.lonLat[1];
    },
    lonHemisphere(): string {
      return this.lon >= 0 ? "E" : "W";
    },
    latHemisphere(): string {
      return this.lat >= 0 ? "N" : "S";
    },
    lonLatStr(): string {
      const { DEGREES } = COORDINATE_FORMAT;

      if (this.coordinateFormat === DEGREES) {
        return toStringHDMS(this.lonLat, 2)
          .replaceAll("° ", "°")
          .replaceAll("′ ", "′")
          .replaceAll("″ ", "″")
          .replaceAll("-", "");
      } else {
        const lon = this.lon.toFixed(6);
        const lat = this.lat.toFixed(6);
        return `${this.latHemisphere} ${lat}, ${this.lonHemisphere} ${lon}`;
      }
    },
    itemsInfoAvailable(): boolean {
      return this.$i18n.locale === "cs";
    },
  },
  data(): PopupOverlayData {
    return {
      popupOverlay: null as Overlay | null,
      coordinateFormat: COORDINATE_FORMAT.DEGREES,
    };
  },
  mounted() {
    this.popupOverlay = new Overlay({
      id: POPUP_OVERLAY_ID,
      element: this.$refs.popup as HTMLElement,
      autoPan: true,
    });

    this.map.addOverlay(this.popupOverlay as Overlay);
  },
  methods: {
    close() {
      if (this.popupOverlay) {
        this.popupOverlay.setPosition(undefined);
      }
    },
    switchCrdFormat() {
      const { DEGREES, DECIMALS } = COORDINATE_FORMAT;

      if (this.coordinateFormat === DEGREES) {
        this.coordinateFormat = DECIMALS;
      } else {
        this.coordinateFormat = DEGREES;
      }
    },
    async crdToClipboard(crd: string) {
      await copyToClipboard(crd);
    },
    createInfoData(features: IInfoFeature[]): IInfoData {
      const locale = this.$i18n.locale;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { geneticTypes } = messages[locale].legend;

      let infoData: IInfoData = {};
      let usedUnits: number[] = [];

      Object.keys(geneticTypes).forEach((key) => {
        const geneticType = geneticTypes[key];
        infoData[geneticType] = [];
      });

      features.forEach((feature) => {
        const { UNIT } = feature.properties;
        const featureData = this.createFeatureData(UNIT);
        const { geneticType } = featureData;

        // only distinct features can be displayed in popup
        if (usedUnits.includes(UNIT)) {
          return;
        }

        infoData[geneticType].push(featureData);
        usedUnits.push(UNIT);
      });

      return infoData;
    },
    createFeatureData(unit: number): IFeatureData {
      const unitStr = unit.toString();
      const index = this.getIndex(unitStr);
      const geneticType = this.getGeneticType(unitStr);
      const geneticTypeId = this.getGeneticTypeId(unitStr);
      const reliefShape = this.getReliefShape(unitStr);
      const images = this.getImages(unitStr);

      return {
        unit,
        index,
        geneticType,
        geneticTypeId,
        reliefShape,
        images,
      };
    },
    getIndex(unitStr: string): number {
      const { index } = legend[unitStr as keyof typeof legend];
      return index;
    },
    getGeneticType(unitStr: string): string {
      const { geneticType } = legend[unitStr as keyof typeof legend];
      const translation = `legend.geneticTypes.${geneticType}`;
      return this.$t(translation);
    },
    getGeneticTypeId(unitStr: string): number {
      const { geneticType } = legend[unitStr as keyof typeof legend];
      return geneticType;
    },
    getReliefShape(unitStr: string): string {
      const translation = `legend.reliefShapes.${unitStr}`;
      return this.$t(translation);
    },
    getImages(unitStr: string): string[] {
      const { img } = legend[unitStr as keyof typeof legend];
      return img;
    },
    getImageSrc(img: string): string {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      return require(`@/assets/legendImages/legend-img-${img}.png`);
    },
    sortFeatures(features: IFeatureData[]): IFeatureData[] {
      return features.sort((a, b) => a.index - b.index);
    },
    showGeneticTypeInfo(geneticTypeId: string) {
      this.$emit("geneticTypeInfoClik", geneticTypeId);
    },
    showReliefShapeInfo(reliefShapeId: string) {
      this.$emit("reliefShapeInfoClik", reliefShapeId);
    },
  },
});
