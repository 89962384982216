import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4becdff0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"width":"100%"} }
const _hoisted_2 = { class: "menu-container" }
const _hoisted_3 = { class: "content" }
const _hoisted_4 = { class: "section" }
const _hoisted_5 = { class: "section-title" }
const _hoisted_6 = { class: "section" }
const _hoisted_7 = { class: "section-title" }
const _hoisted_8 = { class: "section" }
const _hoisted_9 = { class: "section-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SidePanelTitle = _resolveComponent("SidePanelTitle")!
  const _component_LocaleSelect = _resolveComponent("LocaleSelect")!
  const _component_SearchLocation = _resolveComponent("SearchLocation")!
  const _component_Geolocation = _resolveComponent("Geolocation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SidePanelTitle, {
        title: _ctx.$t('sidePanelTitles.menu'),
        onCloseClick: _ctx.emitClose
      }, null, 8, ["title", "onCloseClick"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("menu.selectLanguage")), 1),
          _createVNode(_component_LocaleSelect)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("menu.searchLocation")), 1),
          (_ctx.map)
            ? (_openBlock(), _createBlock(_component_SearchLocation, {
                key: 0,
                map: _ctx.map,
                onSearchSuccess: _ctx.emitClose
              }, null, 8, ["map", "onSearchSuccess"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("menu.myLocation")), 1),
          _createVNode(_component_Geolocation)
        ])
      ])
    ])
  ]))
}